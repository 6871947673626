@media (--print) {
	* {
		background-color: transparent !important;
		color: black !important;
		box-shadow: none !important;
		text-shadow: none !important;
		-ms-filter: none !important;
		filter: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " [https://calschls.org" attr(href) "]";
	}

	abbr[title]:after {
		content: " [" attr(title) "]";
	}

	.ir a:after,
	a[href^="tel:"]:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content: "";
	}  /* Don't show links for images, or javascript/internal links */

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	} /* h5bp.com/t */

	tr, img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	@page {
		margin: 2cm;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	/**
	 * calschls site-specific rules
	 */

	body {
		font: 13pt Georgia, "Times New Roman", Times, serif !important;
		line-height: 1.3 !important;
		background: #fff !important;
		color: #000 !important;
	}

	h1, h2, h3, h4 {
		color: #000;
	}

	h1 {
		font-size: 24pt;
	}

	h2, h3, h4 {
		font-size: 14pt;
		margin-top: 25px;
	}

	a {
		page-break-inside: avoid;
	}

	blockquote {
		page-break-inside: avoid;
	}

	h1, h2, h3, h4, h5, h6 {
		page-break-after: avoid;
		page-break-inside: avoid;
	}

	img {
		page-break-inside: avoid;
		page-break-after: avoid;
	}

	table,
	pre {
		page-break-inside: avoid;
	}
	ul, ol, dl {
		page-break-before: avoid;
	}

	nav {

		padding: 0 6pt 6pt !important;

		svg,
		.topnav {
			display: none;
		}

		.logo:after {
			content: "";
		}

		&.breadcrumbs {
			display: none;
		}
	}

	.viz .qdata,
	.cont_sidebar {
		display: none !important;
	}

	.hero {
		display: block !important;

		&__wrap {
			margin: 0 !important;
		}

		&__cta {
			flex: none !important;
			margin: 12pt 0 0 !important;
			padding: 0 !important;
			text-align: left !important;
			width: 100% !important;

			h1 {
				font-weight: 400 !important;
				margin-bottom: 0 !important;
				padding-bottom: 0 !important;
			}

			.entry {
				font-weight: normal !important;
			}

			ul {
				display: none !important;
			}
		}
	}

	main {

		li,
		p {
			line-height: 1.3 !important;
		}

		a[href$=".pdf"]:after {
			color: #039 !important;
			content: " [https://calschls.org" attr(href) "]" !important;
			font-size: 1em !important;
		}
	}

	a {
		border-bottom-color: #039;
	}

	.cont_article {
		padding: 12pt 6pt !important;

		h1 {
			font-family: var(--font-heading);
		}

		a {
			border-bottom-color: #039;
		}
	}

	body>footer a,
	.cont_article a {
		color: #039 !important;
	}


	.fold,
	.qdata {

		[aria-hidden="true"] {
			clip: auto !important;
			display: block !important;
			height: auto !important;
			margin: 6pt 0 !important;
			opacity: 1 !important;
			overflow: visible !important;
			padding: 6pt 0 !important;
			position: relative !important;
			top: auto !important;
			left: auto !important;
			width: auto !important;
		}

		[aria-expanded] {
			&:after,
			&:before {
				content: "" !important;
			}
		}
	}

}
